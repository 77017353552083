<template>
  <div>
    <label class="form-label small">
      {{ nutrientLabel }} ({{ percentage | round }}%)
      <span class="text-muted fst-italic">&mdash; {{ rawValue }}g</span>
    </label>
    <input
      v-model="percentage"
      :placeholder="nutrientLabel"
      type="range"
      class="form-range"
      min="0"
      max="100"
      step="1"
    >
  </div>
</template>

<script>
export default {
  name: 'NutritionMacronutrientSlider',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    calories: {
      type: Number,
      default: 0,
    },
    nutrientLabel: {
      type: String,
      required: true,
    },
    multiplier: {
      type: Number,
      required: true,
    },
  },
  watch: {
    value() {
      this.syncValue();
    },
    rawValue(newValue, oldValue) {
      if (oldValue) {
        this.$emit('rawValueUpdate', newValue);
      }
    },
    percentage(newValue) {
      this.$emit('percentageUpdate', Math.round(parseFloat(newValue)));
    },
  },
  computed: {
    rawValue() {
      const { calories, percentage, multiplier } = this;
      if (calories === 0 || percentage === 0) {
        return 0;
      }
      const cals = calories * (percentage / 100);
      return Math.round(cals / multiplier);
    },
  },
  methods: {
    syncValue() {
      const { calories, value, multiplier } = this;
      if (calories > 0 && value > 0) {
        const x = value * multiplier;
        this.percentage = (x / calories) * 100;
      }
    },
  },
  data() {
    return {
      percentage: 0,
    };
  },
  mounted() {
    this.syncValue();
  },
};
</script>
